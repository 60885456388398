<template>
	<div class="aboutUs">
		<div class="bgimg">
			<img src="../../assets/img/aboutUs_bg.png" alt />
			<div class="aboutUs_tab">
				<div :class="[tab==0?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(0)">公司简介</div>
				<div :class="[tab==1?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(1)">公司历程</div>
				<div :class="[tab==2?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(2)">公司架构</div>
				<div :class="[tab==3?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(3)">产品线规划</div>
				<div :class="[tab==4?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(4)">核心业务</div>
			</div>
		</div>

		<div class="About_Us" v-if="tab==0">
			<div class="About_Us_culture">
				<img src="../../assets/img/aboutUs_bg2.png" alt />

				<div class="About-Us_concent">
					<div class="About-Us_title">
						<div>公司简介</div>
						<div class="About-Us_title2">About Us</div>
					</div>

					<div class="About-Us_text">
						<div>
							亿事达集团成立于2009年，是武汉本土领先的城市配套商业服务商，现有行业专才500余人。在商业资产运作领域拥有丰富的经验和雄厚的实力，11年时间已经成为一家以核心人才与全能托管为核心价值的专业机构，未来，亿事达将进一步深耕武汉，辐射华中，并逐步走向全国。
						</div>
						<div class="About-Us_title3">
							亿事达集团具备资深的商业投资、顾问咨询、招商代理、项目托管、整体租赁、资产并购及商业地产合作开发实操经验。目前运营管理的商业面积超过80万方，业务涉及大卖场、购物中心、情景街区、主题百货、专业市场、旅游地产等。为商业赋能，为资产增值是我们长期的坚持的使命，亿事达集团亦将为成为中国最优质的商业地产服务商而步履不停。
						</div>
					</div>

					<div class="About-Us_img">
						<img src="../../assets/img/Clipboard@2x.png" alt />
						<div class="About-Us_img_icon1"></div>
						<div class="About-Us_img_icon2"></div>
					</div>
				</div>
			</div>

			<div class="Corporate_culture">
				<div class="Corporate_culture_title">
					<div>企业文化</div>
					<div class="About-Us_title2">Corporate culture</div>
				</div>

				<div class="culture_img culture_item1">
					<div class="culture_icon">
						<img src="../../assets/img/culture_icon.png" alt />
						<div class="culture_icon_text">01</div>
					</div>

					<img style="z-index:12" src="../../assets/img/culture_imgbg.png" alt srcset />
					<img src="../../assets/img/culture_img1.png" alt srcset />
					<div class="culture_img_text">
						<p>诚信</p>
						<p class="culture_img_text_style">自律</p>
					</div>
				</div>
				<div class="culture_img culture_item2">
					<div class="culture_icon">
						<img src="../../assets/img/culture_icon.png" alt />
						<div class="culture_icon_text">02</div>
					</div>
					<img style="z-index:12" src="../../assets/img/culture_imgbg.png" alt srcset />
					<img src="../../assets/img/culture_img2.png" alt srcset />
					<div class="culture_img_text">
						<p>务实</p>
						<p class="culture_img_text_style">简单</p>
					</div>
				</div>
				<div class="culture_img culture_item3">
					<div class="culture_icon">
						<img src="../../assets/img/culture_icon.png" alt />
						<div class="culture_icon_text">03</div>
					</div>
					<img style="z-index:12" src="../../assets/img/culture_imgbg.png" alt srcset />
					<img src="../../assets/img/culture_img3.png" alt srcset />
					<div class="culture_img_text">
						<p>学习</p>
						<p class="culture_img_text_style">创新</p>
					</div>
				</div>
				<div class="culture_img culture_item4">
					<div class="culture_icon">
						<img src="../../assets/img/culture_icon.png" alt />
						<div class="culture_icon_text">04</div>
					</div>
					<img style="z-index:12" src="../../assets/img/culture_imgbg.png" alt srcset />
					<img src="../../assets/img/culture_img4.png" alt srcset />
					<div class="culture_img_text">
						<p>敬业</p>
						<p class="culture_img_text_style">利他</p>
					</div>
				</div>
			</div>

			<div class="culture_bgimg">
				<img class="culture_bgimg_img" src="../../assets/img/culture_bgimg.png" alt srcset />
			</div>
		</div>
		<div class="courseDevelopment" v-if="tab==1">
			<div class="courseDevelopment_content">
				<div class="courseDevelopment_title">
					<div class="courseDevelopment_title_title">公司历程</div>
					<div>The course of development</div>
				</div>

				<div class="timezone">
					<div class="time timeLeft_style12">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img17.png" alt srcset />
						</div>
						<h2>2025年</h2>
						<span class="month">敬请期待</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳广场 | 汉桥筹备中。
							</div>
						</div>
					</div>
					<div class="time timeLeft_style11">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_right_img">
							<img src="../../assets/img/timeLine_img18.png" alt srcset />
						</div>
						<h2>2024年</h2>
						<span class="month">9月</span>
						<div class="time_item_content_left">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳PAI正式开业。
							</div>
						</div>
					</div>
					<div class="time timeLeft_style10">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img16.png" alt srcset />
						</div>
						<h2>2022年</h2>
						<span class="month">10月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 建和开业。
							</div>
						</div>
					</div>
					<div class="time timeLeft_style9">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_right_img">
							<img src="../../assets/img/timeLine_img13.png" alt srcset />
						</div>
						<h2>2021年</h2>
						<span class="month">10月</span>
						<div class="time_item_content_left">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 汉桥小镇正式开业
							</div>
						</div>
						<span class="month month02">9月</span>
						<div class="time_item_content_right time_item_content_left02">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 烽火盛大开业。
							</div>
						</div>
						<div class="item_left_img item_right_img02">
							<img src="../../assets/img/timeLine_img14.png" alt srcset />
						</div>
						<span class="month month03">8月</span>
						<div class="time_item_content_left time_item_content_left03">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 新华家园盛大开业。
							</div>
						</div>
						<div class="item_right_img item_right_img03">
							<img src="../../assets/img/timeLine_img15.png" alt srcset />
						</div>
					</div>

					<div class="time timeLeft_style8">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img12.png" alt srcset />
						</div>
						<h2>2020年</h2>
						<span class="month">12月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 古田盛大开业。
							</div>
						</div>
						<span class="month month02">7月</span>
						<div class="time_item_content_left time_item_content_left02">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								亿事达旗下项目全面更换季佳产品线案
							</div>
							<div>
								名。
							</div>
						</div>
						<div class="item_right_img item_right_img02">
							<img src="../../assets/img/timeLine_img11.png" alt srcset />
						</div>
					</div>
					<div class="time timeLeft_style7">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img10.png" alt srcset />
						</div>
						<h2>2019年</h2>
						<span class="month">8月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 雄楚（原雄楚一号 · 百汇
							</div>
							<div>
								井）2期盛大开业。
							</div>
						</div>
						<span class="month month02">3月</span>
						<div class="time_item_content_left time_item_content_left02">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 美好广场（原美好广场）盛
							</div>
							<div>
								大开业。
							</div>
						</div>
						<div class="item_right_img item_right_img02">
							<img src="../../assets/img/timeLine_img9.png" alt srcset />
						</div>
					</div>

					<div class="time timeLeft_style6">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img8.png" alt srcset />
						</div>
						<h2>2018年</h2>
						<span class="month">10月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 新荣（原虹桥荟）盛大开
							</div>
							<div>
								业。
							</div>
						</div>
						<span class="month month02">1月</span>
						<div class="time_item_content_left time_item_content_left02">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 里 | 纸坊（原江夏 · 华汇广场）
							</div>
							<div>
								盛大开业。
							</div>
						</div>
						<div class="item_right_img item_right_img02">
							<img src="../../assets/img/timeLine_img7.png" alt srcset />
						</div>
					</div>

					<div class="time timeLeft_style5">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img6.png" alt srcset />
						</div>
						<h2>2017年</h2>
						<span class="month">12月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 百步亭（原万家汇 · 新邻
							</div>
							<div>
								里）2期开业。
							</div>
						</div>
						<span class="month month02">9月</span>
						<div class="time_item_content_left time_item_content_left02">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 华林广场盛大开业。
							</div>
						</div>
						<div class="item_right_img item_right_img02">
							<img src="../../assets/img/timeLine_img5.png" alt srcset />
						</div>
					</div>

					<div class="time timeLeft_style4">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img4.png" alt srcset />
						</div>

						<h2>2015年</h2>
						<span class="month">5月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								阳新大酒店盛大开业
							</div>
						</div>
					</div>

					<div class="time timeLeft_style3">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_right_img">
							<img src="../../assets/img/timeLine_img3.png" alt srcset />
						</div>

						<h2>2014年</h2>
						<span class="month">10月</span>
						<div class="time_item_content_left">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 雄楚（原雄楚一号 · 百汇
							</div>
							<div>
								井）1期盛大开业。
							</div>
						</div>
					</div>

					<div class="timeLeft timeLeft_style2">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_left_img">
							<img src="../../assets/img/timeLine_img2.png" alt srcset />
						</div>
						<h2>2013年</h2>
						<span class="month">12月</span>
						<div class="time_item_content_right">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								季佳 · 荟 | 百步亭（原万家汇 · 新邻
							</div>
							<div>
								里）1期开业。
							</div>
						</div>
					</div>

					<div class="timeLeft timeLeft_style1">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div class="item_right_img">
							<img src="../../assets/img/timeLine_img1.png" alt srcset />
						</div>
						<h2>2009年</h2>
						<span class="month">4月</span>
						<div class="time_item_content_left nlefTxt">
							<img class="vector" src="../../assets/img/Vector.png" />
							<div>
								亿事达商业运营管理有限公司正式注册
							</div>
							<div>
								成立
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="CorporateStructure" v-if="tab==2">
			<div class="CorporateStructure_content">
				<div class="courseDevelopment_title">
					<div class="courseDevelopment_title_title">公司架构</div>
					<div>Corporate Structure</div>
				</div>

				<div class="Structure_items">
					<div class="Structure_item Structure_item_style1">
						<img src="../../assets/img/Structure_item1.png" alt />
						<img src="../../assets/img/Structure_img_mask2.png" alt />
						<div class="Structure_item_title">核心层</div>
					</div>
					<div class="Structure_item Structure_item_style2">
						<!-- <img src="../../assets/img/Structure_item2.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">财务中心</div>-->
						<img src="../../assets/img/Structure_item8.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">拓展中心</div>
					</div>
					<div class="Structure_item Structure_item_style3">
						<!-- <img src="../../assets/img/Structure_item3.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">行政中心</div>-->
						<img src="../../assets/img/Structure_item4.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">工程中心</div>
					</div>
					<div class="Structure_item Structure_item_style4">
						<!-- <img src="../../assets/img/Structure_item4.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">工程中心</div>-->
						<img src="../../assets/img/Structure_item3.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">行政中心</div>
					</div>
					<div class="Structure_item Structure_item_style5">
						<!-- <img src="../../assets/img/Structure_item5.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">招商中心</div>-->
						<img src="../../assets/img/Structure_item2.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">财务中心</div>
					</div>
					<div class="Structure_item Structure_item_style6">
						<img src="../../assets/img/Structure_item6.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">运营中心</div>
					</div>
					<div class="Structure_item Structure_item_style7">
						<!-- <img src="../../assets/img/Structure_item7.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">品类规划中心</div>-->
						<img src="../../assets/img/Structure_item10.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">风控中心</div>
					</div>
					<div class="Structure_item Structure_item_style8">
						<!-- <img src="../../assets/img/Structure_item8.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">拓展中心</div>-->
						<svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 119.73388 180.78927">
							<title>树状延展-04</title>
							<path id="路径_109" data-name="路径 109" class="cls-1"
								d="M10.17946,3.313l44.351,68.426,49.518,4.755"
								transform="translate(-4.91897 -2.2252)" />
							<path id="路径_110" data-name="路径 110" class="cls-1" d="M53.97951,70.919l-48.2,22.974"
								transform="translate(-4.91897 -2.2252)" />
							<path id="路径_111" data-name="路径 111" class="cls-1" d="M53.43446,72.287l-42.953,110"
								transform="translate(-4.91897 -2.2252)" />
							<path id="路径_113" data-name="路径 113" class="cls-1" d="M53.9825,72.287l69.138,82.43"
								transform="translate(-4.91897 -2.2252)" />
						</svg>
						<div class="Structure_item_item one_left1">营销企划部</div>
						<div class="Structure_item_item one_left2">经营辅导部</div>
						<div class="Structure_item_item one_left3">营运管理部</div>
						<div class="Structure_item_item one_left4">物业管理部</div>
					</div>

					<div class="Structure_item Structure_item_style9">
						<!-- <img src="../../assets/img/Structure_item9.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">资产管理中心</div>-->
						<img src="../../assets/img/Structure_item5.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">招商中心</div>
					</div>

					<div class="Structure_item Structure_item_style10">
						<!-- <img src="../../assets/img/Structure_item10.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">风控中心</div>-->
						<img src="../../assets/img/Structure_item9.png" alt />
						<img src="../../assets/img/Structure_img_mask.png" alt />
						<div class="Structure_item_title">资管中心</div>
					</div>
					<div class="Structure_item Structure_item_style11">
						<svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 121.98415 183.05263">
							<path id="路径_109" data-name="路径 109" class="cls-1"
								d="M120.00917,2.22954l-44.351,68.426-47.059,4.822"
								transform="translate(-3.44098 -1.14173)" />
							<path id="路径_110" data-name="路径 110" class="cls-1" d="M75.74019,69.83549l48.2,22.974"
								transform="translate(-3.44098 -1.14173)" />
							<path id="路径_111" data-name="路径 111" class="cls-1" d="M76.28218,71.20354l47.3,112.214"
								transform="translate(-3.44098 -1.14173)" />
							<!-- <path id="路径_112" data-name="路径 112" class="cls-1" d="M74.74715,72.44951l-69.805,79.293"
								transform="translate(-3.44098 -1.14173)" /> -->
						</svg>
						<div class="Structure_item_item one_right1">设计部</div>
						<div class="Structure_item_item one_right2">合约部</div>
						<!-- <div class="Structure_item_item one_right3">合约部</div> -->
						<div class="Structure_item_item one_right3">工程部</div>
					</div>

					<div class="Structure_item Structure_item_style12">
						<!-- <img src="../../assets/img/Structure_item11.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">设计部</div>-->
						<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128.9 185.6"
							style="enable-background:new 0 0 128.9 185.6;" xml:space="preserve">
							<path id="路径_109" class="cls-1" d="M26,2.1l59.4,54l38.5,78.5" />
							<path id="路径_110" class="cls-1" d="M85,57.3L21.6,92.7" />
							<path id="路径_111" class="cls-1" d="M86.8,57.3L25.9,183.5" />
						</svg>
						<div class="Structure_item_item two_left1">行政部</div>
						<div class="Structure_item_item two_left2">人力资源部</div>
						<div class="Structure_item_item two_left3">信息技术部</div>
					</div>
					<div class="Structure_item Structure_item_style13">
						<!-- <img src="../../assets/img/Structure_item11.png" alt />
            <img src="../../assets/img/Structure_img_mask.png" alt />
            <div class="Structure_item_title">设计部</div>-->
						<svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 106.73195 171.39549">
							<g>
								<path id="路径_109" data-name="路径 109" class="cls-1"
									d="M103.77816,8.273l-59.429,54.026L5.82113,140.817"
									transform="translate(-4.02564 -6.79314)" />
								<path id="路径_110" data-name="路径 110" class="cls-1" d="M46.33218,61.406l63.45,35.446"
									transform="translate(-4.02564 -6.79314)" />
								<path id="路径_111" data-name="路径 111" class="cls-1" d="M44.54812,61.406l55.341,115.921"
									transform="translate(-4.02564 -6.79314)" />
							</g>
						</svg>
						<div class="Structure_item_item two_right1">品类规划部</div>
						<div class="Structure_item_item two_right2">招商部</div>
						<div class="Structure_item_item two_right3">客服部</div>
					</div>
				</div>
			</div>
		</div>
		<div class="Product_planning" v-if="tab==3">
			<div class="Product_planning_content">
				<div class="courseDevelopment_title">
					<div class="courseDevelopment_title_title">诠释商业新理念</div>
					<div>Explain The New Concept Of Business</div>
					<div class="courseDevelopment_title_title2">
						<span
							style="font-family: Source Han Sans CN, Source Han Sans CN-Normal;color: #333333;">2020年，亿事达将旗下商业项目统一更名，倾力打造“季佳”系列商业品牌。“季佳”系列商业品牌首次提出“乐活邻里，唤醒生活温度”的理念，将在旗下三大产品线当中实现。</span>
					</div>
				</div>

				<div class="courseDevelopment_items">
					<div class="courseDevelopment_item">
						<div class="courseDevelopment_item_img">
							<img src="../../assets/img/Business_img1.png" alt />
						</div>
						<div class="courseDevelopment_item_content_bottom courseDevelopment_item_content">
							<div class="courseDevelopment_item_content_title">季佳广场</div>
							<div class="courseDevelopment_item_content_line"></div>
							<div class="courseDevelopment_item_content_title2">丰富城市生活的体验空间</div>
							<div class="courseDevelopment_item_content_text">
								季佳广场——项目定位为城市级综合型体验购物中心，体量多为8万方以上，更关注消费者的体验价值，打造对于购物需求外的社交、休闲、娱乐、文化、商务洽谈、亲子家庭的沉浸式体验空间。
							</div>
						</div>
					</div>
					<div class="courseDevelopment_item courseDevelopment_item_style">
						<div class="courseDevelopment_item_content_top courseDevelopment_item_content">
							<div class="courseDevelopment_item_content_title">季佳 · 荟</div>
							<div class="courseDevelopment_item_content_line"></div>
							<div class="courseDevelopment_item_content_title2">品质新邻里生活的策源地</div>
							<div class="courseDevelopment_item_content_text">季佳 ·
								荟——打造承载“新邻里”内涵的社区型商业综合体，项目体量多为3-5万方，为整个社区打造多远业态组合。</div>
						</div>
						<div class="courseDevelopment_item_img courseDevelopment_item_content_bottom">
							<img src="../../assets/img/Business_img2.png" alt />
						</div>
					</div>
					<div class="courseDevelopment_item">
						<div class="courseDevelopment_item_img">
							<img src="../../assets/img/Business_img3.png" alt />
						</div>
						<div class="courseDevelopment_item_content_bottom courseDevelopment_item_content">
							<div class="courseDevelopment_item_content_title">季佳 · 里</div>
							<div class="courseDevelopment_item_content_line"></div>
							<div class="courseDevelopment_item_content_title2">诠释家庭生活的美好日常</div>
							<div class="courseDevelopment_item_content_text">季佳 · 里——约3万方体验式社区商业体，作为消费者日常消费及社交欢聚的纽带。
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="core_business" v-if="tab==4">
			<div class="courseDevelopment_title">
				<div class="courseDevelopment_title_title">全阶段商业资产操盘能力</div>
				<div>Full-stage Commercial Asset Trading Capabilities</div>
			</div>

			<div class="core_business_items">
				<div class="core_business_item core_business_item_an1">
					<img src="../../assets/img/capabilities_img1.png" alt />
					<div class="core_business_item_text">商业委托运营管理</div>
					<div class="core_business_item_hover">
						<div class="item_hover_item">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<div class="item_hover_item_text">
								<div class="item_hover_item_text_title">商业委托运营管理</div>
								<div class="item_hover_item_text_text">
									公司拥有先进的商业模式健全的管理体系，强大的专业团队，为开发商和大宗资本持有者提供资产委托运营服务。运用 专业化手段结合当地市场环境及物业情况，进行全过程资产
									优化增值服务，包括从拿地决策、规划设计、建设顾问、空间 设计、商铺分割、动线规划、平面布局、业态定位、业态组合、 品牌招商、驻场运营等全方位服务。</div>
							</div>
						</div>
					</div>
				</div>
				<div class="core_business_item core_business_item_style core_business_item_an2">
					<img src="../../assets/img/capabilities_img2.png" alt />
					<div class="core_business_item_text">商业项目整租运营</div>
					<div class="core_business_item_hover">
						<div class="item_hover_item">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<div class="item_hover_item_text">
								<div class="item_hover_item_text_title">商业项目整租运营</div>
								<div class="item_hover_item_text_text">
									公司拥有大量的品牌资源及专业化团队，可以整体承租开发商或大宗资产持有者持有的优质资产。运用专业化手段结合当地市场环境及商铺物业情况进行全过程资产优化增值服务，确保资产持续增值。
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="core_business_item core_business_item_an3">
					<img src="../../assets/img/capabilities_img3.png" alt />
					<div class="core_business_item_text">商业资产并购</div>
					<div class="core_business_item_hover">
						<div class="item_hover_item">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<div class="item_hover_item_text">
								<div class="item_hover_item_text_title">商业资产并购</div>
								<div class="item_hover_item_text_text">
									公司拥有充足的现金流和强大的资本支持，有能力对金融机构的不良资产、经营不善的商业资产，以及产权方需处置的商业资产进行整体并购，快速满足资产持有者的变现需求。</div>
							</div>
						</div>
					</div>
				</div>
				<div class="core_business_item core_business_item_style core_business_item_an4">
					<img src="../../assets/img/capabilities_img4.png" alt />
					<div class="core_business_item_text">地产合作开发</div>
					<div class="core_business_item_hover">
						<div class="item_hover_item">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<div class="item_hover_item_text">
								<div class="item_hover_item_text_title">地产合作开发</div>
								<div class="item_hover_item_text_text">
									公司拥有丰富的商业地产操作经验和品牌资源，使得亿事达在地产行业赢得了良好的口碑，能通过强大的专业团队保障商业地产项目快速实现开发目标，并能在后续的商业运营环节使产权方和品牌方实现超出预期的高额回报。
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="core_business_partner">
				<div class="courseDevelopment_title">
					<div class="courseDevelopment_title_style">合作伙伴</div>
					<div>Partners</div>
				</div>

				<div class="core_business_partner_item">
					<div class="Partner_item" v-for="(item,index) in Partners" :key="index">
						<div class="Partners_item">
							<img :src="item.img" alt />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import {
		getIndexB
	} from "@/api/api";
	export default {
		name: "aboutUs",
		metaInfo: {
			title: "关于我们", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "亿事达信息,亿事达架构,亿事达产品,亿事达业务"
				},
				{
					name: "description",
					content: "亿事达信息"
				}
			]
		},
		data() {
			return {
				tab: 0,
				Partners: []
			};
		},
		created() {
			this.getIndexB();
		},
		mounted() {
			this.$store.commit("updateTab", 2);
			if (this.$route.query.subtab) {
				this.tab = this.$route.query.subtab;
			}
		},
		methods: {
			// // 改变场数
			// changefield: _debounce(function() {
			//   // do something ...
			// }, 100)

			getIndexB() {
				let body3 = {
					siteModle: "0",
					site: "2"
				};
				getIndexB(body3)
					.then(res => {
						console.log(res.data);
						this.Partners = res.data.retData;
					})
					.catch(err => {
						console.log(err);
					});
			},

			// 循环判断是否要触发过渡
			handleScroll(e) {
				for (var i = 0; i < this.fadeInElements.length; i++) {
					var elem = this.fadeInElements[i];
					if (this.isElemVisible(elem)) {
						elem.style.cssText = `
                opacity: 1;
                filter: alpha(opacity = 100);
                filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
                -webkit-transform:translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                transform: translateY(0px);
                -webkit-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                -moz-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                -ms-transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
                transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);`;
						this.fadeInElements.splice(i, 1); // 只让它运行一次
					}
				}
			},
			// 判断元素距离窗口的位置
			isElemVisible(el) {
				var rect = el.getBoundingClientRect();
				var elemTop = rect.top + 200; // 200 = buffer
				var elemBottom = rect.bottom;
				return elemTop < window.innerHeight && elemBottom >= 0;
			},
			choosebtn(tab) {
				this.tab = tab;
			}
		}
	};
</script>
<style scoped lang="scss">
	$vm_w_base: 1920;
	$vm_h_base: 1080;

	@function vm_w($px) {
		@return ($px / 1920) * 100vw;
	}

	@keyframes show-in {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	@keyframes show-in1 {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateY(-30px);
			-webkit-box-transform: translateY(-30px);
			-moz-box-transform: translateY(-30px);
			-ms-box-transform: translateY(-30px);
			-o-box-transform: translateY(-30px);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			transform: translateY(0px);
			-webkit-box-transform: translateY(0px);
			-moz-box-transform: translateY(0px);
			-ms-box-transform: translateY(0px);
			-o-box-transform: translateY(0px);
		}
	}

	@keyframes show-in2 {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateX(-30px);
			-webkit-box-transform: translateX(-30px);
			-moz-box-transform: translateX(-30px);
			-ms-box-transform: translateX(-30px);
			-o-box-transform: translateX(-30px);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			transform: translateX(0px);
			-webkit-box-transform: translateX(0px);
			-moz-box-transform: translateX(0px);
			-ms-box-transform: translateX(0px);
			-o-box-transform: translateX(0px);
		}
	}

	@keyframes show-in3 {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateX(30px);
			-webkit-box-transform: translateX(30px);
			-moz-box-transform: translateX(30px);
			-ms-box-transform: translateX(30px);
			-o-box-transform: translateX(30px);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			transform: translateX(0px);
			-webkit-box-transform: translateX(0px);
			-moz-box-transform: translateX(0px);
			-ms-box-transform: translateX(0px);
			-o-box-transform: translateX(0px);
		}
	}

	@keyframes show-in4 {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateY(vm_w(30));
			-webkit-box-transform: translateY(vm_w(30));
			-moz-box-transform: translateY(vm_w(30));
			-ms-box-transform: translateY(vm_w(30));
			-o-box-transform: translateY(vm_w(30));
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			transform: translateY(0px);
			-webkit-box-transform: translateY(0);
			-moz-box-transform: translateY(0);
			-ms-box-transform: translateY(0);
			-o-box-transform: translateY(0);
		}
	}

	@keyframes fade-in {
		from {
			transform: translateY(vm_w(30));
			-webkit-box-transform: translateY(vm_w(30));
			-moz-box-transform: translateY(vm_w(30));
			-ms-box-transform: translateY(vm_w(30));
			-o-box-transform: translateY(vm_w(30));
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		to {
			transform: translateY(0);
			-webkit-box-transform: translateY(0);
			-moz-box-transform: translateY(0);
			-ms-box-transform: translateY(0);
			-o-box-transform: translateY(0);

			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	@keyframes fade-bottom {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateY(vm_w(-50));
			-webkit-box-transform: translateY(vm_w(-50));
			-moz-box-transform: translateY(vm_w(-50));
			-ms-box-transform: translateY(vm_w(-50));
			-o-box-transform: translateY(vm_w(-50));
		}

		to {
			transform: translateY(0);
			-webkit-box-transform: translateY(0);
			-moz-box-transform: translateY(0);
			-ms-box-transform: translateY(0);
			-o-box-transform: translateY(0);
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	@keyframes fade-top {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateY(vm_w(50));
		}

		to {
			transform: translateY(0);
			-webkit-box-transform: translateY(0);
			-moz-box-transform: translateY(0);
			-ms-box-transform: translateY(0);
			-o-box-transform: translateY(0);
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	.bgimg {
		width: 100%;
		position: relative;
	}

	.bgimg img {
		width: 100%;
	}

	.aboutUs_tab {
		width: vm_w(1200);
		height: vm_w(64);
		background-color: #ffffff;
		position: absolute;
		bottom: vm_w(-32);
		z-index: 99;
		left: calc(50% - #{vm_w(600)});
		left: -webkit-calc(50% - #{vm_w(600)});
		left: -moz-calc(50% - #{vm_w(600)});
		left: -ms-calc(50% - #{vm_w(600)});
		left: -o-calc(50% - #{vm_w(600)});
		box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-ms-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-o-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
	}

	.aboutUs_tab_item {
		width: 20%;
		height: 100%;
		line-height: vm_w(64);
		display: inline-block;
		vertical-align: top;
		text-align: center;
		cursor: pointer;
		z-index: 99;
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #666666;
	}

	.aboutUs_tab_item2 {
		width: 20%;
		height: 100%;
		line-height: vm_w(64);
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		z-index: 99;
		text-align: center;
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: $chooseColor;
	}

	.About_Us {
		width: 100%;
	}

	.About_Us_culture {
		width: 100%;
		height: vm_w(930);
		position: relative;
	}

	.About_Us img {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
	}

	.About-Us_concent {
		position: relative;
		max-width: vm_w(1192);
		height: 100%;
		margin: 0 auto;
		z-index: 10;
	}

	.About-Us_title {
		position: absolute;
		/* width: 163px; */
		width: 100%;
		margin-top: vm_w(83);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: center;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.About-Us_title2 {
		margin-top: vm_w(14);
	}

	.About-Us_title3 {
		margin-top: vm_w(35);
	}

	.title_icon {
		width: vm_w(4);
		display: inline;
		height: vm_w(72);
		background-color: #124db3;
		float: left;
		margin-top: vm_w(10);
	}

	.About-Us_text {
		position: absolute;
		margin-top: vm_w(335);
		max-width: vm_w(478);
		font-size: vm_w(14);
		color: #666666;
		line-height: vm_w(24);
		z-index: 11;
		font-family: Source Han Sans CN, Source Han Sans CN-Normal;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.About-Us_img {
		position: absolute;
		top: vm_w(304);
		right: vm_w(86);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.About-Us_img img {
		width: vm_w(510);
		z-index: 10;
		position: relative;
	}

	.About-Us_img_icon1 {
		position: absolute;
		z-index: 9;
		top: vm_w(-102);
		right: vm_w(-102);
		width: vm_w(376);
		height: vm_w(376);
		background-color: $chooseColor;
	}

	.About-Us_img_icon2 {
		position: absolute;
		z-index: 9;
		bottom: vm_w(-44);
		left: vm_w(-44);
		width: vm_w(102);
		height: vm_w(102);
		border: solid 2px $chooseColor;
	}

	.Corporate_culture {
		width: 100%;
		height: vm_w(307);
		position: relative;
	}

	.Corporate_culture_title {
		position: absolute;
		/* width: 163px; */
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		text-align: center;
		top: vm_w(-174);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.culture_img {
		position: absolute;
		width: vm_w(274);
		height: vm_w(651);
		z-index: 10;
	}

	.culture_img img {
		position: absolute;
		width: 100%;
		top: 0;
		animation: fade-top 0.6s;
		-webkit-animation: fade-top 0.6s;
		-moz-animation: fade-top 0.6s;
		-ms-animation: fade-top 0.6s;
		-o-animation: fade-top 0.6s;
		transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);

		-webkit-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
	}

	.culture_icon {
		width: vm_w(134);
		height: vm_w(177);
		position: absolute;
		z-index: 10;
		top: vm_w(-186);
		left: calc(50% - #{vm_w(67)});
		left: -webkit-calc(50% - #{vm_w(67)});
		left: -moz-calc(50% - #{vm_w(67)});
		left: -ms-calc(50% - #{vm_w(67)});
		left: -o-calc(50% - #{vm_w(67)});
		animation: fade-bottom 0.6s;
		-webkit-animation: fade-bottom 0.6s;
		-moz-animation: fade-bottom 0.6s;
		-ms-animation: fade-bottom 0.6s;
		-o-animation: fade-bottom 0.6s;
		transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
	}

	.culture_item1 {
		left: vm_w(364);
		top: vm_w(134);
	}

	.culture_item2 {
		left: vm_w(673);
		top: vm_w(134);
	}

	.culture_item3 {
		left: vm_w(979);
		top: vm_w(134);
	}

	.culture_item4 {
		left: vm_w(1285);
		top: vm_w(134);
	}

	.culture_icon img {
		width: 100%;
		position: absolute;
		top: 0px;
	}

	.culture_icon_text {
		position: absolute;
		width: 100%;
		z-index: 11;
		top: vm_w(37);
		text-align: center;
		font-size: vm_w(64);
		font-family: Arial, Arial-Bold;
		font-weight: 700;
		color: #ffffff;
	}

	.culture_img_text {
		position: absolute;
		width: 100%;
		z-index: 20;
		top: vm_w(281);
		text-align: center;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Bold;
		font-weight: 700;
		color: #ffffff;
		animation: fade-bottom 0.6s;
		-webkit-animation: fade-bottom 0.6s;
		-moz-animation: fade-bottom 0.6s;
		-ms-animation: fade-bottom 0.6s;
		-o-animation: fade-bottom 0.6s;
		transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 0.6s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
	}

	.culture_bgimg {
		width: 100%;
		height: vm_w(560);
	}

	.culture_bgimg_img {
		position: relative !important;
		width: 100%;
		height: 100%;
	}

	.courseDevelopment_content {
		width: vm_w(1200);
		margin: 0 auto;
	}

	.courseDevelopment_title {
		width: 100%;
		text-align: center;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	//时间轴

	.timezone {
		width: vm_w(4);
		height: vm_w(6940);
		background: #d9d9d9;
		margin: 0 auto;
		margin-top: vm_w(32);
		position: relative;
		margin-bottom: vm_w(178);
		animation: heightSlide 0.5s linear;
		// -webkit-animation: heightSlide 0.5s linear;
		// -moz-animation: heightSlide 0.5s linear;
		// -ms-animation: heightSlide 0.5s linear;
		// -o-animation: heightSlide 0.5s linear;
	}

	@keyframes heightSlide {
		0% {
			height: 0;
		}

		100% {
			height: vm_w(350);
		}
	}

	.timezone:after {
		content: "未完待续...";
		width: vm_w(100);
		color: #fff;
		position: absolute;
		margin-left: vm_w(-35);
		bottom: vm_w(-30);
		animation: showIn 4s ease;
		-webkit-animation: showIn 4s ease;
		-moz-animation: showIn 4s ease;
		-ms-animation: showIn 4s ease;
		-o-animation: showIn 4s ease;
	}

	.timezone .time,
	.timezone .timeLeft {
		position: absolute;
		margin-left: vm_w(-49);
		width: vm_w(104);
		height: vm_w(40);
		background: $chooseColor;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
		animation: showIn ease;
		-webkit-animation: showIn ease;
		-moz-animation: showIn ease;
		-ms-animation: showIn ease;
		-o-animation: showIn ease;

		& span:nth-child(1) {
			position: absolute;
			left: vm_w(-4);
			top: vm_w(-4);
			padding: vm_w(9);
			border-style: solid;
			border-color: $chooseColor;
			border-width: vm_w(1) 0 0 vm_w(1);
		}

		& span:nth-child(2) {
			position: absolute;
			right: vm_w(-4);
			top: vm_w(-4);
			padding: vm_w(9);
			border-style: solid;
			border-color: $chooseColor;
			border-width: vm_w(1) vm_w(1) 0 0;
		}

		& span:nth-child(3) {
			position: absolute;
			right: vm_w(-4);
			bottom: vm_w(-4);
			padding: vm_w(9);
			border-style: solid;
			border-color: $chooseColor;
			border-width: 0 vm_w(1) vm_w(1) 0;
		}

		& span:nth-child(4) {
			position: absolute;
			left: vm_w(-4);
			bottom: vm_w(-4);
			padding: vm_w(9);
			border-style: solid;
			border-color: $chooseColor;
			border-width: 0 0 vm_w(1) vm_w(1);
		}
	}

	.timezone .time:nth-child(1) {
		animation-duration: 1s;
		-webkit-animation-duration: 1s;
		-moz-animation-duration: 1s;
		-ms-animation-duration: 1s;
		-o-animation-duration: 1s;
	}

	.timezone .timeLeft:nth-child(2) {
		animation-duration: 1.5s;
		-webkit-animation-duration: 1.5s;
		-moz-animation-duration: 1.5s;
		-ms-animation-duration: 1.5s;
		-o-animation-duration: 1.5s;
	}

	.timezone .time:nth-child(3) {
		animation-duration: 2s;
		-webkit-animation-duration: 2s;
		-moz-animation-duration: 2s;
		-ms-animation-duration: 2s;
		-o-animation-duration: 2s;
	}

	.timezone .timeLeft:nth-child(4) {
		animation-duration: 2.5s;
		-webkit-animation-duration: 2.5s;
		-moz-animation-duration: 2.5s;
		-ms-animation-duration: 2.5s;
		-o-animation-duration: 2.5s;
	}

	@keyframes showIn {

		0%,
		70% {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		100% {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	.timezone .time h2,
	.timezone .timeLeft h2 {
		color: #ffffff;
		font-size: vm_w(18);
		line-height: vm_w(40);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		text-align: center;
		animation: showIn 2s ease;
		-webkit-animation: showIn 2s ease;
		-moz-animation: showIn 2s ease;
		-ms-animation: showIn 2s ease;
		-o-animation: showIn 2s ease;
	}

	.timezone .timeLeft .month,
	.timezone .time .month {
		display: inline-block;
		width: vm_w(58);
		height: vm_w(28);
		border: 1px solid $chooseColor;
		border-radius: vm_w(65);
		line-height: vm_w(28);
		text-align: center;
		color: $chooseColor;
		position: absolute;
		top: vm_w(211);
		left: 50%;
		margin-left: vm_w(-29);
		box-sizing: border-box;
		background-color: #fff;
	}

	.timezone .timeLeft .month02,
	.timezone .time .month02 {
		top: vm_w(535);
	}

	.timezone .timeLeft .month03,
	.timezone .time .month03 {
		top: vm_w(860);
	}

	.timezone .time:hover,
	.timezone .timeLeft:hover {
		// border: 4px solid lightblue;
		// background: lemonchiffon;
		// box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.4);
	}

	.time_item_content_left,
	.time_item_content_right {
		font-size: vm_w(18);
		color: #333;
		position: absolute;
		line-height: vm_w(30);
		right: vm_w(-478);
		top: vm_w(162);
		box-sizing: border-box;
		padding: vm_w(32);
		padding-left: vm_w(56);
		width: vm_w(415);
		height: vm_w(126);
		background: url(../../assets/img/Union1.png);
		background-size: cover;
		border-radius: 1px;
		z-index: 2;
		overflow: hidden;
		animation: showIn 2s ease;
		-webkit-animation: showIn 2s ease;
		-moz-animation: showIn 2s ease;
		-ms-animation: showIn 2s ease;
		-o-animation: showIn 2s ease;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}

	.time_item_content_left .vector,
	.time_item_content_right .vector {
		width: vm_w(16);
		height: vm_w(16);
		position: absolute;
		left: vm_w(32);
		top: vm_w(37);
	}

	.time_item_content_left {
		left: vm_w(-478);
	}
	.time_item_content_right{
		background:url(../../assets/img/Union.png);
		background-size: cover;
		padding-left: vm_w(77);
	}
	.time_item_content_right .vector {
		left: vm_w(53);
	}
	.time_item_content_right_text1 {
		font-size: vm_w(14);
		margin-left: vm_w(8);
	}

	// .timezone .time div p,
	// .timezone .timeLeft div p {
	//   color: #fff;
	//   font-weight: bold;
	//   text-align: center;
	// }
	.timezone .time:before,
	.timezone .timeLeft:before {
		content: "";
		position: absolute;
		border-width: vm_w(12);
		border-style: solid;
		border-color: $chooseColor transparent transparent transparent;
		animation: showIn ease;
		-webkit-animation: showIn ease;
		-moz-animation: showIn ease;
		-ms-animation: showIn ease;
		-o-animation: showIn ease;
	}

	.timezone .time:before {
		top: vm_w(40);
		left: vm_w(38);
	}

	.timezone .timeLeft:before {
		right: vm_w(38);
		top: vm_w(40);
	}

	.timezone .time div ul,
	.timezone .timeLeft div ul {
		list-style: none;
		width: vm_w(300);
		padding: vm_w(5) 0 0;
		border-top: vm_w(2) solid #eee;
		color: #fff;
		text-align: center;
	}

	.timezone .time div li,
	.timezone .timeLeft div li {
		display: inline-block;
		height: vm_w(25);
		line-height: vm_w(25);
	}

	.culture_img_text_style {
		margin-top: vm_w(28);
	}

	// .time_item_content {
	//   width: 0;
	//   height: 0;
	//   border-top: 100px solid red;
	//   border-left: 100px solid transparent;
	// }
	.item_right_icon {
		width: 0;
		display: inline-block;
		height: 0;
		border-bottom: vm_w(12) solid $chooseColor;
		border-right: vm_w(12) solid transparent;
	}

	.timeLeft_style1 {
		top: vm_w(6119 + 409);
	}

	.timeLeft_style2 {
		top: vm_w(5704 + 409);
	}

	.timeLeft_style3 {
		top: vm_w(5289 + 409);
	}

	.timeLeft_style4 {
		top: vm_w(4874 + 409);
	}

	.timeLeft_style5 {
		top: vm_w(4130 + 409);
	}

	.timeLeft_style6 {
		top: vm_w(3386 + 409);
	}

	.timeLeft_style7 {
		top: vm_w(2642 + 409);
	}

	.timeLeft_style8 {
		top: vm_w(1898 + 409);
	}

	.timeLeft_style9 {
		top: vm_w(826 + 409);
	}

	.timeLeft_style10 {
		top: vm_w(409 + 409);
	}

	.timeLeft_style11 {
		top: vm_w(409);
	}
	.timeLeft_style12{
		top: 0;
	}

	.item_right_title {
		display: inline-block;
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 700;
		color: $chooseColor;
		margin-left: vm_w(8);
	}

	.item_left_img {
		width: vm_w(420);
		height: vm_w(300);
		position: absolute;
		top: vm_w(65);
		left: vm_w(-478);
	}

	.item_left_img img {
		width: 100%;
	}

	.item_right_img {
		width: vm_w(420);
		height: vm_w(300);
		position: absolute;
		top: vm_w(65);
		right: vm_w(-478);
		animation: showIn 2s ease;
		-webkit-animation: showIn 2s ease;
		-moz-animation: showIn 2s ease;
		-ms-animation: showIn 2s ease;
		-o-animation: showIn 2s ease;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}

	.item_right_img02,
	.item_left_img02 {
		top: vm_w(390);
	}

	.item_right_img03,
	.item_left_img03 {
		top: vm_w(715);
	}

	.time_item_content_left02,
	.time_item_content_right02 {
		top: vm_w(490);
	}

	.time_item_content_left03,
	.time_item_content_right03 {
		top: vm_w(818);
	}

	.item_right_img img {
		width: 100%;
	}

	.courseDevelopment_title_title {
		margin-top: vm_w(93);
		margin-bottom: vm_w(10);
	}

	.time_item_content_right_text2 {
		margin-left: vm_w(20);
		margin-top: vm_w(6);
	}

	.CorporateStructure_content {
		width: vm_w(1200);
		margin: 0 auto;
		padding-bottom: vm_w(64);
	}

	.Structure_items {
		width: 100%;
		height: vm_w(870);
		position: relative;
	}

	.Structure_item_style1 {
		top: vm_w(292);
		left: vm_w(450);
		animation: show-in 1s;
		-webkit-animation: show-in 1s;
		-moz-animation: show-in 1s;
		-ms-animation: show-in 1s;
		-o-animation: show-in 1s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
	}

	.Structure_item_style2 {
		top: vm_w(32);
		left: vm_w(450);
		opacity: 0;
		animation: show-in1 1s 0.1s;
		-webkit-animation: show-in1 1s 0.1s;
		-moz-animation: show-in1 1s 0.1s;
		-ms-animation: show-in1 1s 0.1s;
		-o-animation: show-in1 1s 0.1s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style3 {
		top: vm_w(423);
		right: vm_w(233);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 0.2s;
		-webkit-animation: show-in4 1s 0.2s;
		-moz-animation: show-in4 1s 0.2s;
		-ms-animation: show-in4 1s 0.2s;
		-o-animation: show-in4 1s 0.2s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style4 {
		top: vm_w(162);
		left: vm_w(225);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in2 1s 0.3s;
		-webkit-animation: show-in2 1s 0.3s;
		-moz-animation: show-in2 1s 0.3s;
		-ms-animation: show-in2 1s 0.3s;
		-o-animation: show-in2 1s 0.3s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style5 {
		top: vm_w(553);
		left: vm_w(450);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 0.4s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style6 {
		top: vm_w(423);
		left: vm_w(225);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 0.5s;
		-webkit-animation: show-in4 1s 0.5s;
		-moz-animation: show-in4 1s 0.5s;
		-ms-animation: show-in4 1s 0.5s;
		-o-animation: show-in4 1s 0.5s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style7 {
		top: vm_w(292);
		left: vm_w(0);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in2 1s 0.6s;
		-webkit-animation: show-in2 1s 0.6s;
		-moz-animation: show-in2 1s 0.6s;
		-ms-animation: show-in2 1s 0.6s;
		-o-animation: show-in2 1s 0.6s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style8 {
		width: vm_w(117) !important;
		height: vm_w(185) !important;
		top: vm_w(601);
		left: vm_w(209);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 0.7s;
		-webkit-animation: show-in4 1s 0.7s;
		-moz-animation: show-in4 1s 0.7s;
		-ms-animation: show-in4 1s 0.7s;
		-o-animation: show-in4 1s 0.7s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	@keyframes lineMove {
		0% {
			stroke-dasharray: 0, 300;
			stroke-dashoffset: -300;
		}

		50% {
			stroke-dasharray: 150, 300;
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}

		100% {
			stroke-dasharray: 300, 300;
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	.cls-1 {
		fill: none;
		stroke: #019fab;
		stroke-width: 4px;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: lineMove 3s ease-out 0.6s;
		-webkit-animation: lineMove 3s ease-out 0.6s;
		-moz-animation: lineMove 3s ease-out 0.6s;
		-ms-animation: lineMove 3s ease-out 0.6s;
		-o-animation: lineMove 3s ease-out 0.6s;

		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style9 {
		top: vm_w(162);
		right: vm_w(233);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in1 1s 0.8s;
		-webkit-animation: show-in1 1s 0.8s;
		-moz-animation: show-in1 1s 0.8s;
		-ms-animation: show-in1 1s 0.8s;
		-o-animation: show-in1 1s 0.8s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style10 {
		top: vm_w(292);
		right: vm_w(8);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in3 1s 0.9s;
		-webkit-animation: show-in3 1s 0.9s;
		-moz-animation: show-in3 1s 0.9s;
		-ms-animation: show-in3 1s 0.9s;
		-o-animation: show-in3 1s 0.9s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style11 {
		width: vm_w(117) !important;
		height: vm_w(185) !important;
		top: vm_w(601);
		right: vm_w(219);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 1s;
		-webkit-animation: show-in4 1s 1s;
		-moz-animation: show-in4 1s 1s;
		-ms-animation: show-in4 1s 1s;
		-o-animation: show-in4 1s 1s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style12 {
		width: vm_w(117) !important;
		height: vm_w(185) !important;
		top: vm_w(46);
		left: vm_w(189);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 1s;
		-webkit-animation: show-in4 1s 1s;
		-moz-animation: show-in4 1s 1s;
		-ms-animation: show-in4 1s 1s;
		-o-animation: show-in4 1s 1s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Structure_item_style13 {
		width: vm_w(117) !important;
		height: vm_w(185) !important;
		top: vm_w(19);
		right: vm_w(189);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 1s 1s;
		-webkit-animation: show-in4 1s 1s;
		-moz-animation: show-in4 1s 1s;
		-ms-animation: show-in4 1s 1s;
		-o-animation: show-in4 1s 1s;
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.courseDevelopment_item_style {
		margin-left: vm_w(32);
		margin-right: vm_w(32);
	}

	.Structure_item {
		position: absolute;
		width: vm_w(292);
		height: vm_w(253);

		.Structure_item_item {
			position: absolute;
			width: vm_w(140);
			height: vm_w(68);
			text-align: center;
			color: #ffffff;
			background-color: #019fab;
			line-height: vm_w(68);
			text-align: center;
			font-size: vm_w(20);
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			border-radius: 50%;
		}
	}

	.one_right1 {
		top: vm_w(-34);
		right: vm_w(-133);
	}

	.one_right2 {
		top: vm_w(60);
		right: vm_w(-133);
	}

	.one_right3 {
		top: vm_w(143);
		right: vm_w(-133);
	}

	.one_right4 {
		top: vm_w(143);
		right: vm_w(70);
	}

	.one_left1 {
		top: vm_w(-34);
		left: vm_w(-133);
	}

	.one_left2 {
		top: vm_w(60);
		left: vm_w(-133);
	}

	.one_left3 {
		top: vm_w(143);
		left: vm_w(-133);
	}

	.one_left4 {
		top: vm_w(143);
		left: vm_w(70);
	}

	.two_left1 {
		top: vm_w(-30);
		left: vm_w(-113);
	}

	.two_left2 {
		top: vm_w(48);
		left: vm_w(-113);
	}

	.two_left3 {
		top: vm_w(131);
		left: vm_w(-113);
	}

	.two_right1 {
		top: vm_w(-15);
		right: vm_w(-113);
	}

	.two_right2 {
		top: vm_w(60);
		right: vm_w(-113);
	}

	.two_right3 {
		top: vm_w(133);
		right: vm_w(-113);
	}

	.Structure_item img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
	}

	.Structure_item_title {
		width: 100%;
		position: absolute;
		text-align: center;
		font-size: vm_w(40);
		font-family: Source Han Sans CN, Source Han Sans CN-Bold;
		font-weight: 700;
		color: #ffffff;
		line-height: vm_w(253);
	}

	.courseDevelopment_title_style {
		margin-top: vm_w(60);
		margin-bottom: vm_w(10);
	}

	.Product_planning_content {
		width: vm_w(1200);
		margin: 0 auto;
	}

	.courseDevelopment_items {
		width: 100%;
		box-sizing: border-box;
	}

	.core_business_item_style {
		margin-left: vm_w(32);
	}

	.core_business_item_an1 {
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.core_business_item_an2 {
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.core_business_item_an3 {
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.3s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.core_business_item_an4 {
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
		-webkit-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
		-moz-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
		-ms-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.4s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.courseDevelopment_title_title2 {
		width: vm_w(804);
		margin: 0 auto;
		margin-top: vm_w(16);
		text-align: left;
		line-height: vm_w(24);
		text-align: center;
		font-size: vm_w(16);
	}

	.courseDevelopment_item {
		width: vm_w(376);
		height: vm_w(408);
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
	}

	.courseDevelopment_item_img {
		width: 100%;
		height: vm_w(204);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in1 1s;
		-webkit-animation: show-in1 1s;
		-moz-animation: show-in1 1s;
		-ms-animation: show-in1 1s;
		-o-animation: show-in1 1s;

		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.courseDevelopment_item_img img {
		width: 100%;
		height: 100%;
	}

	.courseDevelopment_item_content {
		width: 100%;
		height: vm_w(204);
		background-color: #f5f5f5;
		position: relative;
		z-index: 100;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in4 0.2s;
		-webkit-animation: show-in4 0.2s;
		-moz-animation: show-in4 0.2s;
		-ms-animation: show-in4 0.2s;
		-o-animation: show-in4 0.2s;

		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		transition: opacity 1s cubic-bezier(0.32, 0.94, 0.6, 1),
			transform 1s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		-ms-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.courseDevelopment_item_content_title {
		padding-top: vm_w(24);
		padding-left: vm_w(24);
		font-size: vm_w(18);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 600;
		text-align: left;
		color: $chooseColor;
	}

	.courseDevelopment_item_content_line {
		margin-left: vm_w(24);
		margin-top: vm_w(8);
		width: vm_w(70);
		height: vm_w(4);
		background-color: $chooseColor;
	}

	.courseDevelopment_item_content_title2 {
		margin-left: vm_w(24);
		margin-top: vm_w(8);
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #333333;
	}

	.courseDevelopment_item_content_text {
		width: vm_w(331);
		margin: vm_w(8) auto 0px auto;
		font-size: vm_w(14);
		font-family: Source Han Sans CN, Source Han Sans CN-Normal;
		color: #666666;
		line-height: vm_w(22);
	}

	.courseDevelopment_item_content_bottom:before {
		content: "";
		position: absolute;
		top: vm_w(-30);
		left: vm_w(24);
		z-index: 100;
		width: 0px;
		height: 0px;
		border: vm_w(15) solid transparent;
		border-bottom: vm_w(15) solid #f5f5f5;
	}

	.courseDevelopment_item_content_top {
		animation: show-in1 1s !important;
		-webkit-animation: show-in1 1s !important;
		-moz-animation: show-in1 1s !important;
		-ms-animation: show-in1 1s !important;
		-o-animation: show-in1 1s !important;

		animation-fill-mode: forwards !important;
		-webkit-animation-fill-mode: forwards !important;
		-moz-animation-fill-mode: forwards !important;
		-ms-animation-fill-mode: forwards !important;
		-o-animation-fill-mode: forwards !important;
	}

	.courseDevelopment_item_content_bottom {
		animation: show-in4 1s !important;
		-webkit-animation: show-in4 1s !important;
		-moz-animation: show-in4 1s !important;
		-ms-animation: show-in4 1s !important;
		-o-animation: show-in4 1s !important;
		animation-fill-mode: forwards !important;
		-webkit-animation-fill-mode: forwards !important;
		-moz-animation-fill-mode: forwards !important;
		-ms-animation-fill-mode: forwards !important;
		-o-animation-fill-mode: forwards !important;
	}

	.courseDevelopment_item_content_top:before {
		content: "";
		position: absolute;
		bottom: vm_w(-30);
		left: vm_w(24);
		width: 0px;
		z-index: 100;
		height: 0px;
		border: vm_w(15) solid transparent;
		border-top: vm_w(15) solid #f5f5f5;
	}

	.courseDevelopment_items {
		margin-top: vm_w(32);
		padding-bottom: vm_w(64);
	}

	.core_business_items {
		width: vm_w(1200);
		margin: 0 auto;
	}

	.core_business_item {
		width: vm_w(580);
		height: vm_w(580);
		display: inline-block;
		vertical-align: top;
		margin-top: vm_w(32);
		position: relative;
	}

	.item_hover_item {
		width: vm_w(438);
		height: vm_w(277);
		position: absolute;
		top: calc(50% - #{vm_w(138.5)});
		top: -webkit-calc(50% - #{vm_w(138.5)});
		top: -moz-calc(50% - #{vm_w(138.5)});
		top: -ms-calc(50% - #{vm_w(138.5)});
		top: -o-calc(50% - #{vm_w(138.5)});

		left: calc(50% - #{vm_w(219)});
		left: -webkit-calc(50% - #{vm_w(219)});
		left: -moz-calc(50% - #{vm_w(219)});
		left: -ms-calc(50% - #{vm_w(219)});
		left: -o-calc(50% - #{vm_w(219)});

		& span:nth-child(1) {
			position: absolute;
			left: 0px;
			top: 0px;
			padding: vm_w(12);
			border-style: solid;
			border-color: #ffffff;
			border-width: vm_w(2) 0 0 vm_w(2);
		}

		& span:nth-child(2) {
			position: absolute;
			right: 0px;
			top: 0px;
			padding: vm_w(12);
			border-style: solid;
			border-color: #ffffff;
			border-width: vm_w(2) vm_w(2) 0 0;
		}

		& span:nth-child(3) {
			position: absolute;
			right: 0px;
			bottom: 0px;
			padding: vm_w(12);
			border-style: solid;
			border-color: #ffffff;
			border-width: 0 vm_w(2) vm_w(2) 0;
		}

		& span:nth-child(4) {
			position: absolute;
			left: 0px;
			bottom: 0px;
			padding: vm_w(12);
			border-style: solid;
			border-color: #ffffff;
			border-width: 0 0 vm_w(2) vm_w(2);
		}
	}

	.core_business_item_hover {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		background-color: rgba(38, 89, 177, 0.3);
		opacity: 0;

		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
	}

	.core_business_item:hover {
		opacity: 1;
		filter: alpha(opacity=100);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		overflow: auto;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
		overflow-x: hidden;
	}

	.core_business_item:hover .core_business_item_hover {
		opacity: 1;
		filter: alpha(opacity=100);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
	}

	// .core_business_item_hover:hover.core_business_item_text {
	//   opacity: 0;
	// }
	.core_business_item:hover .core_business_item_text {
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
	}

	.core_business_item img {
		width: 100%;
		height: 100%;
	}

	.Partner_item {
		margin-left: vm_w(32);
		display: inline-block;
	}

	.Partners_item {
		width: vm_w(208);
		height: vm_w(70);
		z-index: 5;
		margin-top: vm_w(34);
	}

	.Partners_item img {
		width: 100%;
		height: 100%;
	}

	.partner_item_img {
		width: vm_w(171);
		height: vm_w(75);
		margin-top: vm_w(20);
	}

	.partner_item_img img {
		width: 100%;
		border-radius: 4px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
	}

	.core_business_partner_item {
		width: vm_w(1208);
		margin: 0 auto;
		margin-top: vm_w(10);
		padding-bottom: vm_w(60);
		display: table;
	}

	.core_business_item_text {
		position: absolute;
		opacity: 1;
		filter: alpha(opacity=100);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
		font-size: vm_w(24);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: $chooseColor;
	}

	.item_hover_item_text {
		width: vm_w(376);
		position: absolute;
		top: 50%;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		transform: translateY(-50%);
		-webkit-box-transform: translateY(-50%);
		-moz-box-transform: translateY(-50%);
		-ms-box-transform: translateY(-50%);
		-o-box-transform: translateY(-50%);

		color: #ffffff;
		line-height: vm_w(24);
	}

	.item_hover_item_text_title {
		text-align: center;
		width: 100%;
		font-size: vm_w(18);
		font-family: Source Han Sans CN, Source Han Sans CN-Bold;
		font-weight: 700;
	}

	.item_hover_item_text_text {
		margin-top: vm_w(7);
		font-size: vm_w(14);
		font-family: Source Han Sans CN, Source Han Sans CN-Normal;
		color: #ffffff;
	}
</style>
